<template>
    <c-panel>
        <c-grid v-if="order?.data" class="spacing-xl-all">
            <c-grid-item class="width-100-sm width-100-md width-70-lg width-60-xl">
                <c-wizard>
                    <c-wizard-item>
                        <template #label>1. Address</template>
                        <form>
                            <c-grid class="widths-100-all" v-if="order.data?.shipping">
                                <c-grid-item>
                                    <c-textbox label="Email" v-model="order.data.contact.email" autocomplete="email" />
                                </c-grid-item>
                                <c-grid-item v-if="order.data.shipping.dropShipping">
                                    <h2 class="mt-5 mb-0">Recipient Address:</h2>
                                </c-grid-item>
                                <c-grid-item>
                                    <c-textbox
                                        label="Name"
                                        v-model="order.data.shipping.address.name"
                                        autocomplete="name"
                                    />
                                </c-grid-item>
                                <c-grid-item>
                                    <c-textbox
                                        label="Address"
                                        v-model="order.data.shipping.address.street1"
                                        autocomplete="street-address"
                                    />
                                    <div v-if="!showAddressLine2" class="flex f-justify-end mt-1">
                                        <c-link @click="showAddressLine2 = true">+ Address Line 2</c-link>
                                    </div>
                                </c-grid-item>
                                <c-grid-item v-if="showAddressLine2">
                                    <c-textbox v-model="order.data.shipping.address.street2" />
                                </c-grid-item>
                                <c-grid-item class="width-40-all">
                                    <c-textbox
                                        label="City"
                                        v-model="order.data.shipping.address.city"
                                        autocomplete="address-level2"
                                    />
                                </c-grid-item>
                                <c-grid-item class="width-35-all">
                                    <c-select
                                        label="State"
                                        :options="states"
                                        :display="(state) => state?.name"
                                        :map="(state) => state.abbreviation"
                                        v-model="order.data.shipping.address.state"
                                        autocomplete="address-level1"
                                    />
                                </c-grid-item>
                                <c-grid-item class="width-25-all">
                                    <c-textbox
                                        label="Zip"
                                        v-model="order.data.shipping.address.zip"
                                        autocomplete="postal-code"
                                    />
                                </c-grid-item>
                                <template v-if="order.data.shipping.dropShipping">
                                    <c-grid-item>
                                        <h2 class="mt-5 mb-0">Sender Address:</h2>
                                    </c-grid-item>
                                    <c-grid-item>
                                        <c-textbox
                                            label="Name"
                                            v-model="order.data.shipping.address.name"
                                            autocomplete="name"
                                        />
                                    </c-grid-item>
                                    <c-grid-item>
                                        <c-textbox
                                            label="Address"
                                            v-model="order.data.shipping.address.street1"
                                            autocomplete="street-address"
                                        />
                                        <div v-if="!showAddressLine2" class="flex f-justify-end mt-1">
                                            <c-link @click="showAddressLine2 = true">+ Address Line 2</c-link>
                                        </div>
                                    </c-grid-item>
                                    <c-grid-item v-if="showAddressLine2">
                                        <c-textbox v-model="order.data.shipping.address.street2" />
                                    </c-grid-item>
                                    <c-grid-item class="width-40-all">
                                        <c-textbox
                                            label="City"
                                            v-model="order.data.shipping.address.city"
                                            autocomplete="address-level2"
                                        />
                                    </c-grid-item>
                                    <c-grid-item class="width-35-all">
                                        <c-select
                                            label="State"
                                            :options="states"
                                            :display="(state) => state?.name"
                                            :map="(state) => state.abbreviation"
                                            v-model="order.data.shipping.address.state"
                                            autocomplete="address-level1"
                                        />
                                    </c-grid-item>
                                    <c-grid-item class="width-25-all">
                                        <c-textbox
                                            label="Zip"
                                            v-model="order.data.shipping.address.zip"
                                            autocomplete="postal-code"
                                        />
                                    </c-grid-item>
                                </template>
                                <c-grid-item>
                                    <c-checkbox label="Drop Shipping" v-model="order.data.shipping.dropShipping" />
                                </c-grid-item>
                            </c-grid>
                        </form>
                        <template #error v-if="addressError">{{ addressError }}</template>
                        <template #button="slotProps">
                            <c-button @click="completeAddress(slotProps.next)" :disabled="!validateAddress()">
                                Continue
                            </c-button>
                        </template>
                    </c-wizard-item>
                    <c-wizard-item>
                        <template #label>2. Shipping</template>
                        <c-grid class="widths-100-all">
                            <c-grid-item v-for="(method, index) in shippingMethods || []" :key="index">
                                <c-radio class="my-2" :value="method" v-model="order.data.shipping.method">
                                    <c-grid class="f-align-center" style="width: 100%">
                                        <c-grid-item class="width-grow-all">
                                            <h3>{{ method.service }}</h3>
                                            <span class="text-light text-xs">
                                                Arrives in {{ method.days || 1 }} to
                                                {{ (method.days || 1) + 3 }} business days
                                            </span>
                                        </c-grid-item>
                                        <c-grid-item v-if="method.price > 0">
                                            <h2>${{ Math.round(method.price) }}</h2>
                                        </c-grid-item>
                                        <c-grid-item v-else>
                                            <h2>Free</h2>
                                        </c-grid-item>
                                    </c-grid>
                                </c-radio>
                            </c-grid-item>
                        </c-grid>
                        <template #button="slotProps">
                            <c-button @click="completeShipping(slotProps.next)">Continue</c-button>
                        </template>
                    </c-wizard-item>
                    <c-wizard-item>
                        <template #label>3. Payment</template>
                        <c-grid v-if="card">
                            <c-grid-item class="width-50-all width-100-xs">
                                <c-textbox
                                    label="Card Number"
                                    type="credit-card-number"
                                    v-model="card.number"
                                    autocomplete="cc-number"
                                />
                            </c-grid-item>
                            <c-grid-item class="width-25-all width-50-xs">
                                <c-textbox
                                    label="Exp"
                                    placeholder="MM / YY"
                                    type="credit-card-expiration"
                                    v-model="card.expiration"
                                    autocomplete="cc-exp"
                                />
                            </c-grid-item>
                            <c-grid-item class="width-25-all width-50-xs">
                                <c-textbox
                                    label="CVC"
                                    type="credit-card-cvc"
                                    v-model="card.cvc"
                                    autocomplete="cc-csc"
                                />
                            </c-grid-item>
                        </c-grid>
                        <template #error v-if="paymentError">{{ paymentError }}</template>
                        <template #button="slotProps">
                            <c-button-group>
                                <c-button @click="completePayment(slotProps.next)" :disabled="!validatePayment()">
                                    Continue
                                </c-button>
                                <!--<c-button @click="slotProps.next()">Skip</c-button>-->
                            </c-button-group>
                        </template>
                    </c-wizard-item>
                    <c-wizard-item>
                        <template #label>4. Review</template>
                        <c-grid>
                            <c-grid-item class="width-100-sm width-50-md width-100-lg width-55-xl">
                                <c-grid class="widths-100-all">
                                    <c-grid-item>
                                        <h2>Contact</h2>
                                        <span>{{ order.data.contact.email }}</span>
                                    </c-grid-item>
                                    <c-grid-item>
                                        <h2>Shipping</h2>
                                        <p>
                                            {{ order.data.shipping.address.name }}<br />
                                            {{ order.data.shipping.address.street1 }}<br />
                                            <template v-if="order.data.shipping.address.street2">
                                                {{ order.data.shipping.address.street2 }}<br />
                                            </template>
                                            {{ order.data.shipping.address.city }},
                                            {{ order.data.shipping.address.state }}
                                            {{ order.data.shipping.address.zip }}
                                        </p>
                                        <p>Method: {{ order.data.shipping.method.service }}</p>
                                    </c-grid-item>
                                </c-grid>
                            </c-grid-item>
                            <c-grid-item class="width-100-sm width-50-md width-100-lg width-45-xl">
                                <h2 class="mb-3">Order Summary</h2>
                                <c-grid class="widths-100-all">
                                    <c-grid-item>
                                        <c-order-invoice :orderId="order.id" />
                                    </c-grid-item>
                                    <c-grid-item>
                                        <c-button class="large" @click="placeOrder()">Place Order</c-button>
                                    </c-grid-item>
                                </c-grid>
                            </c-grid-item>
                        </c-grid>
                    </c-wizard-item>
                </c-wizard>
            </c-grid-item>
            <c-grid-item class="width-100-sm width-100-md width-30-lg width-40-xl">
                <div class="mb-5 pb-5" v-for="(item, index) in order.data.cart" :key="index">
                    <c-grid class="text-sm">
                        <c-grid-item class="width-100-all">
                            <c-card>
                                <c-image :image="item.image" v-if="item.image" :zoom="true" />
                            </c-card>
                        </c-grid-item>
                        <c-grid-item class="width-grow-all">
                            <h2>{{ product(item)?.name }}</h2>
                            <p class="p-0 m-0" v-if="item.size">
                                <span class="text-bold">Size: </span>
                                <span>{{ item.size.width }} &times; {{ item.size.height }}</span>
                            </p>
                            <p class="p-0 m-0" v-for="(option, selection) in item.selections" :key="selection">
                                <span class="text-bold">{{ selection }}: </span>
                                <span>{{ option }}</span>
                            </p>
                            <p class="p-0 m-0">
                                <span class="text-bold">Quantity: </span>
                                <span>{{ item.quantity }}</span>
                            </p>
                        </c-grid-item>
                        <c-grid-item class="width-shrink-all">
                            <h2>${{ item.price }}</h2>
                        </c-grid-item>
                    </c-grid>
                </div>
            </c-grid-item>
        </c-grid>
    </c-panel>
</template>

<script lang="ts">
import { inject, ref } from "vue";
import { Database } from "vuebase";
import { Card, CartItem, Product, ShippingMethod } from "../main";
import OrderController from "@/controllers/order-controller";
import { useRoute, useRouter } from "vue-router";
import states from "states-us";

export default {
    name: "Checkout",
    setup() {
        const route = useRoute();
        const router = useRouter();
        const orderId = (route.params.id as string) || undefined;
        const database = inject(Database.InjectionKey);
        const orderController = new OrderController(orderId);
        const order = orderController.order;
        const products = database?.collection<Product>("products").documents();

        function product(item: CartItem): Product | undefined {
            return products?.find((product) => product.id == item.productId)?.data;
        }

        //
        //
        // 1. Address
        const addressError = ref();
        const showAddressLine2 = ref(false);

        function validateAddress(): boolean {
            if (
                order.data?.contact.email &&
                order.data?.shipping?.address.name &&
                order.data?.shipping?.address.street1 &&
                order.data?.shipping?.address.city &&
                order.data?.shipping?.address.state &&
                order.data?.shipping?.address.zip
            ) {
                return true;
            }

            return false;
        }

        function completeAddress(next: () => void): Promise<void> {
            return Promise.all([order.save(), getShippingMethods()])
                .then(() => {
                    // Remove the error
                    addressError.value = undefined;
                    next();
                })
                .catch(() => {
                    addressError.value =
                        "Failed to process your shipping address. Please verify that it is correct and try again.";
                });
        }

        //
        //
        // 2. Shipping
        const shippingMethods = ref<ShippingMethod[]>();

        function getShippingMethods(): Promise<void> {
            return orderController.getShippingMethods().then((methods) => {
                shippingMethods.value = methods;
                if (order.data && order.data.shipping) {
                    order.data.shipping.method = shippingMethods.value[0];
                }
            });
        }

        function completeShipping(next: () => void): Promise<void> {
            return order.save().then(() => {
                next();
            });
        }

        //
        //
        // 3. Payment
        const card = ref<Card>({ number: "", cvc: "", expiration: "" });
        const paymentError = ref<string>();

        function validatePayment(): boolean {
            if (card.value.number && card.value.expiration && card.value.cvc) {
                return true;
            }

            return false;
        }

        function completePayment(next: () => void): Promise<void> {
            return orderController
                .setupCard(card.value)
                .then(() => {
                    paymentError.value = undefined;
                    next();
                })
                .catch(() => {
                    paymentError.value =
                        "There was an error processing your card. Verify that the details are correct and try again. If the issue persists, contact daniel@cohoprints.com";
                });
        }

        //
        //
        // 4. Review
        function placeOrder(): Promise<void> {
            return orderController
                .placeOrder()
                .then(() => {
                    return router.push("/order/" + order.id);
                })
                .then();
        }

        return {
            order,
            product,
            states: states.filter((s) => s.territory == false),
            addressError,
            showAddressLine2,
            validateAddress,
            completeAddress,
            shippingMethods,
            getShippingMethods,
            completeShipping,
            card,
            paymentError,
            validatePayment,
            completePayment,
            placeOrder,
        };
    },
};
</script>
