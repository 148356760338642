
import { inject, ref } from "vue";
import { Database } from "vuebase";
import { Card, CartItem, Product, ShippingMethod } from "../main";
import OrderController from "@/controllers/order-controller";
import { useRoute, useRouter } from "vue-router";
import states from "states-us";

export default {
    name: "Checkout",
    setup() {
        const route = useRoute();
        const router = useRouter();
        const orderId = (route.params.id as string) || undefined;
        const database = inject(Database.InjectionKey);
        const orderController = new OrderController(orderId);
        const order = orderController.order;
        const products = database?.collection<Product>("products").documents();

        function product(item: CartItem): Product | undefined {
            return products?.find((product) => product.id == item.productId)?.data;
        }

        //
        //
        // 1. Address
        const addressError = ref();
        const showAddressLine2 = ref(false);

        function validateAddress(): boolean {
            if (
                order.data?.contact.email &&
                order.data?.shipping?.address.name &&
                order.data?.shipping?.address.street1 &&
                order.data?.shipping?.address.city &&
                order.data?.shipping?.address.state &&
                order.data?.shipping?.address.zip
            ) {
                return true;
            }

            return false;
        }

        function completeAddress(next: () => void): Promise<void> {
            return Promise.all([order.save(), getShippingMethods()])
                .then(() => {
                    // Remove the error
                    addressError.value = undefined;
                    next();
                })
                .catch(() => {
                    addressError.value =
                        "Failed to process your shipping address. Please verify that it is correct and try again.";
                });
        }

        //
        //
        // 2. Shipping
        const shippingMethods = ref<ShippingMethod[]>();

        function getShippingMethods(): Promise<void> {
            return orderController.getShippingMethods().then((methods) => {
                shippingMethods.value = methods;
                if (order.data && order.data.shipping) {
                    order.data.shipping.method = shippingMethods.value[0];
                }
            });
        }

        function completeShipping(next: () => void): Promise<void> {
            return order.save().then(() => {
                next();
            });
        }

        //
        //
        // 3. Payment
        const card = ref<Card>({ number: "", cvc: "", expiration: "" });
        const paymentError = ref<string>();

        function validatePayment(): boolean {
            if (card.value.number && card.value.expiration && card.value.cvc) {
                return true;
            }

            return false;
        }

        function completePayment(next: () => void): Promise<void> {
            return orderController
                .setupCard(card.value)
                .then(() => {
                    paymentError.value = undefined;
                    next();
                })
                .catch(() => {
                    paymentError.value =
                        "There was an error processing your card. Verify that the details are correct and try again. If the issue persists, contact daniel@cohoprints.com";
                });
        }

        //
        //
        // 4. Review
        function placeOrder(): Promise<void> {
            return orderController
                .placeOrder()
                .then(() => {
                    return router.push("/order/" + order.id);
                })
                .then();
        }

        return {
            order,
            product,
            states: states.filter((s) => s.territory == false),
            addressError,
            showAddressLine2,
            validateAddress,
            completeAddress,
            shippingMethods,
            getShippingMethods,
            completeShipping,
            card,
            paymentError,
            validatePayment,
            completePayment,
            placeOrder,
        };
    },
};
